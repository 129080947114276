const bo_routes = [
  {
    path: '',
    redirect: { name: 'BoLogin' },
  },
  {
    path: 'login',
    name: 'BoLogin',
    component: () => import('@/views/backend/BoLogin.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'empty',
    }
  },
  {
    path: 'dashboard/:pageSlug?',
    name: 'BoDashboard',
    component: () => import('@/views/backend/BoDashboard.vue'),
    meta: {
      menu: 'BoDashboard',
      pageTitle: 'Dashboard',
      objName: 'Dashboard',
    },
  },
  {
    path: 'home/:pageSlug?',
    name: 'BoHome',
    component: () => import('@/views/backend/BoHome/BoHome.vue'),
    meta: {
      menu: 'BoHome',
      pageTitle: 'Home',
      objName: 'Home',
    },
  },
  {
    path: 'account-setting',
    name: 'BoAccountSetting',
    component: () => import('@/views/backend/BoAccountSetting.vue'),
    meta: {
      menu: 'BoAccount',
      pageTitle: 'Account Setting',
      objName: 'Account',      
    },
  },
  {
    path: 'user/:pageSlug?',
    name: 'Users',
    component: () => import('@/views/backend/BoUser/List.vue'),
    meta: {
      menu: "Users",
      pageTitle: 'Users',
      hasCta: 'true',
      objName: 'User'
    },
  },
  {
    path: 'user-level/:pageSlug?',
    name: 'BoUserLevel',
    component: () => import('@/views/backend/BoUserLevel/List.vue'),
    meta: {
      menu: 'BoUserLevel',
      pageTitle: 'User Level',
      hasCta: 'true',
      objName: 'User Level'
    },
  },
  {
    path: 'news-list/:pageSlug?',
    name: 'BoBlog',
    component: () => import('@/views/backend/BoBlog/List.vue'),
    meta: {
      menu:'BoBlog',
      pageTitle: 'News List',
      hasCta: true,
      objName: 'News'
    }
  },
  {
    path: 'event-list/:pageSlug?',
    name: 'BoEvent',
    component: () => import('@/views/backend/BoEvent/List.vue'),
    meta: {
      menu:'BoEvent',
      pageTitle: 'Event List',
      hasCta: true,
      objName: 'Event'
    }
  },

  {
    path: 'our-works/:pageSlug?',
    name: 'BoOurWorks',
    component: () => import('@/views/backend/BoOurWorks/List.vue'),
    meta: {
      pageTitle: 'Our Works',
      menu: 'BoOurWorks',
      objName: 'Our Works',
    }
  },
  {
    path: 'our-works-category/:pageSlug?',
    name: 'BoOurWorksCategory',
    component: () => import('@/views/backend/BoOurWorksCategory/List.vue'),
    meta: {
      pageTitle: 'Our Works Category',
      menu: 'BoOurWorksCategory',
      objName: 'Our Works Category',
    }
  },
  {
    path: 'journal/:pageSlug?',
    name: 'BoJournal',
    component: () => import('@/views/backend/BoJournal/List.vue'),
    meta: {
      pageTitle: 'Journal',
      hasCta: true,
      menu: 'BoJournal',
      objName: 'Journal',
    }
  },
  {
    path: 'journal-category/:pageSlug?',
    name: 'BoJournalCategory',
    component: () => import('@/views/backend/BoJournalCategory/List.vue'),
    meta: {
      pageTitle: 'Journal Category',
      hasCta: true,
      menu: 'BoJournalCategory',
      objName: 'Journal Category',
    }
  },

  {
    path: 'legal/terms-and-conditions',
    name: 'BoTermCondition',
    component: () => import('@/views/backend/BoLegal/Terms.vue'),
    meta: {
      pageTitle: 'Legal',
      menu: 'BoTermCondition',
      hasBreadCrumb: false,
      objName: 'Term and Conditions',
    }
  },
  {
    path: 'legal/privacy-policy',
    name: 'BoPrivacyPolicy',
    component: () => import('@/views/backend/BoLegal/Privacy.vue'),
    meta: {
      pageTitle: 'Legal',
      menu: 'BoPrivacyPolicy',
      hasBreadCrumb: false,
      objName: 'Privacy Policy',
    }
  },
  {
    path: 'legal/cookie-policy',
    name: 'BoCookiePolicy',
    component: () => import('@/views/backend/BoLegal/Cookie.vue'),
    meta: {
      pageTitle: 'Legal',
      menu: 'BoCookiePolicy',
      hasBreadCrumb: false,
      objName: 'Cookie Policy',
    }
  },



  {
    path: 'services/:pageSlug?',
    name: 'BoServices',
    component: () => import('@/views/backend/BoServices/List.vue'),
    meta: {
      menu:'BoServices',
      pageTitle: 'Services',
      objName: 'Services'
    }
  },
  {
    path: 'industries/:pageSlug?',
    name: 'BoIndustries',
    component: () => import('@/views/backend/BoIndustries/List.vue'),
    meta: {
      menu:'BoIndustries',
      pageTitle: 'Industries',
      objName: 'Industries'
    }
  },
  {
    path: 'client-list/:pageSlug?',
    name: 'BoClients',
    component: () => import('@/views/backend/BoClients/List.vue'),
    meta: {
      menu:'BoClients',
      pageTitle: 'Client',
      objName: 'Clients'
    }
  },
  {
    path: 'testimonials/:pageSlug?',
    name: 'BoTestimonials',
    component: () => import('@/views/backend/BoTestimonials/List.vue'),
    meta: {
      menu:'BoTestimonials',
      pageTitle: 'Testimonials',
      objName: 'Testimonials'
    }
  },

  {
    path: 'blog-category/:pageSlug?',
    name: 'BoBlogCategory',
    component: () => import('@/views/backend/BoBlogCategory/List.vue'),
    meta: {
      menu: 'BoBlogCategory',
      pageTitle: 'Blog Category',
      hasCta: true,
      objName: 'Blog Category'
    }
  },
  {
    path: 'contact/:pageSlug?',
    name: 'BoContact',
    component: () => import( /* webpackChunkName: "about" */ '@/views/backend/BoContact/BoContact.vue'),
    meta: {
      pageTitle: 'Contact',
      menu: 'BoContact',
      objName: 'Contact',
    }
  }, {
    path: 'contact-page',
    name: 'BoContactPage',
    component: () => import( /* webpackChunkName: "about" */ '@/views/backend/BoContact/BoContactPage.vue'),
    meta: {
      pageTitle: 'Contact Page',
      menu: 'BoContactPage',
      objName: 'Contact Page',
    }
  },
  {
    path: 'home',
    name: 'BoHome',
    component: () => import('@/views/backend/BoHome/BoHome.vue'),
    meta: {
      menu: 'BoHome',
      pageTitle: 'Home',
      objName: 'Home'
    }
  },
  {
    path: 'life-at-l9',
    name: 'BoLifeAtL9',
    component: () => import('@/views/backend/BoLifeAtL9/BoLifeAtL9.vue'),
    meta: {
      menu: 'BoLifeAtL9',
      pageTitle: 'Life At Lingkar 9',
      objName: 'Life At Lingkar 9'
    }
  },
  {
    path: 'career-page/:pageSlug?',
    name: 'BoCareerPage',
    component: () => import('@/views/backend/BoCareer/BoCareerPage.vue'),
    meta: {
      menu: 'BoCareerPage',
      pageTitle: 'Career Page',
      objName: 'Career Page',
    }
  },
  {
    path: 'career/:pageSlug?',
    name: 'BoCareer',
    component: () => import('@/views/backend/BoCareer/BoCareer.vue'),
    meta: {
      menu: 'BoCareer',
      pageTitle: 'Career Page',
      objName: 'Career Page',
    }
  },
  {
    path: 'inquiry/:pageSlug?',
    name: 'BoInquiry',
    component: () => import('@/views/backend/BoInquiry/List.vue'),
    meta: {
      menu: 'BoInquiry',
      pageTitle: 'Inquiry Page',
      objName: 'Inquiry Page',
    }
  },
  {
    path: 'job-applicants/:pageSlug?',
    name: 'BoApplicants',
    component: () => import('@/views/backend/BoCareer/BoApplicants.vue'),
    meta: {
      menu: 'BoApplicants',
      hasCta: false,
      pageTitle: 'Job Applicants',
      objName: 'Job Applicants',
    }
  },
  
  {
    path: 'web-settings',
    name: 'BoWebSettings',
    component: () => import('@/views/backend/BoWebSettings/BoWebSettings.vue'),
    meta: {
      pageTitle: 'Web Settings',
      menu: 'WebSettings',
      objName: 'Web Settings'
    }
  },
]
export default bo_routes